<template>
  <div class="segment-chart" :class="{ squeeze }">
    <div class="chart">
      <template v-for="(segment, index) in segments">
        <span
          v-if="segment.total"
          class="item"
          :style="{ width: getPercentageLabel(segment.total) }"
          :key="`segment-${index}`"
        ></span>
      </template>
    </div>
    <div class="legend">
      <SegmentChartLegendItem
        v-for="(segmentItem, index) in segments"
        :key="`segement-item-${index}`"
        :values="segmentItem"
      />
    </div>
  </div>
</template>

<script>
import SegmentChartLegendItem from "@/components/SegmentChartLegendItem";
export default {
  name: "SegementChart",
  props: ["segments", "squeeze"],
  components: {
    SegmentChartLegendItem,
  },
  computed: {
    totalCount: function () {
      let total = 0;

      this.segments.map((val) => {
        total += val.total;
      });

      return total;
    },
  },
  methods: {
    getPercentage: function (val) {
      return val / this.totalCount;
    },
    getPercentageLabel: function (val) {
      return Math.round(this.getPercentage(val) * 100).toString() + "%";
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.segment-chart {
  .chart {
    display: flex;
    padding: 0 8px;

    .item {
      background-color: #e0e0e0;
      border-right: solid 2px #fff;
      border-left: solid 2px #fff;
      height: 8px;
      transition: width 0.425s;

      &:first-of-type {
        position: relative;
        border-left: none;

        &:before {
          content: " ";
          display: block;
          height: 8px;
          width: 8px;
          border-radius: 4px 0 0 4px;
          position: absolute;
          left: -8px;
          background: inherit;
        }
      }

      &:last-of-type {
        position: relative;
        border-right: none;

        &:after {
          content: " ";
          display: block;
          height: 8px;
          width: 8px;
          border-radius: 0 4px 4px 0;
          position: absolute;
          right: -8px;
          background: inherit;
        }
      }

      @import "@/scss/color_sequence";
    }
  }

  .legend {
    display: flex;
    margin-top: 0.75rem;

    .segment-chart-legend-item {
      &:nth-child(1) {
        .segment-chart-legend-item-indicator {
          background: $light-blue-tint0;
        }
      }

      &:nth-child(2) {
        .segment-chart-legend-item-indicator {
          background: $light-blue-tint1;
        }
      }

      &:nth-child(3) {
        .segment-chart-legend-item-indicator {
          background: $light-blue-tint2;
        }
      }

      &:nth-child(4) {
        .segment-chart-legend-item-indicator {
          background: $light-blue-tint3;
        }
      }

      &:nth-child(5) {
        .segment-chart-legend-item-indicator {
          background: $light-blue-tint4;
        }
      }

      &:nth-child(6) {
        .segment-chart-legend-item-indicator {
          background: $dark-blue;
        }
      }

      &:nth-child(7) {
        .segment-chart-legend-item-indicator {
          background: $green;
        }
      }

      &:nth-child(8) {
        .segment-chart-legend-item-indicator {
          background: $purple;
        }
      }

      &:nth-child(9) {
        .segment-chart-legend-item-indicator {
          background: $dark-blue-bg;
        }
      }
    }
  }
}
</style>