<template>
  <figure v-lazyimage class="image-wrapper">
    <img
      class="image-wrapper__item"
      :data-url="src"
      alt="" />
  </figure>
</template>

<script>

export default {
  name: "BaseImage",
  components: {

  },
  props: {
    src: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false
    }
  }
};
</script>

<style lang="scss">
.image-wrapper {
  margin: 0;

  &--loaded {
    .image-wrapper__item {
      opacity: 1;
    }
  }

  &__item {
    transition: all 0.425s ease-in-out;
    opacity: 0;
  }
}
</style>