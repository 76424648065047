<template>
	<div class="segment-chart-legend-item">
		<div class="segment-chart-legend-item-wrapper">
			<span class="segment-chart-legend-item-indicator"></span>
			<span class="segment-chart-legend-item-metric">{{ values.total | number }}</span>
		</div>
		<span class="segment-chart-legend-item-description">{{ values.label }}</span>
	</div>
</template>

<script>
	export default {
		name: 'SegmentChartLegendItem',
		props: [ 'values' ]
	}
</script>

<style lang="scss">
	@import '@/scss/colors';

	.segment-chart-legend-item {
		margin-right: 2rem;

		.segment-chart-legend-item-wrapper {
			display: flex;
			align-items: center;

			.segment-chart-legend-item-indicator {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: #e0e0e0;
				margin-right: 6px;
			}

			.segment-chart-legend-item-metric {
				font-weight: 600;
			}
		}

		.segment-chart-legend-item-description {
			margin-left: 12px;
			font-size: 0.75rem;
			color: $medium-gray;
		}
	}

	.squeeze {
		.segment-chart-legend-item {
			margin-right: 1.125rem;
		}
	}
</style>