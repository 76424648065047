<template>
  <div class="persona-list-item">
    <div class="persona-list-item__image">
      <BaseImage :src="description.image" />
    </div>
    <div class="persona-list-item__details">
      <div class="persona-list-item__heading">
        {{ description.name }}
      </div>
      <div class="persona-list-item__subheading">
        {{ persona.case_opportunities }} Case Opportunities
      </div>
    </div>
    <div class="persona-list-item__metrics">
      <SegmentChart :segments="payerMix" />
    </div>
    <div class="persona-list-item__actions" v-if="actions.length">
      <button
        class="button outline"
        v-for="(action, index) in actions"
        :key="`action-${index}`"
        @click="action.onClick"
      >
        {{ action.label }}
      </button>
    </div>
    <div class="persona-list-item__label" v-if="detailed">
      <Pill
        :count="$_audiences_getAccessLabelByClusterId(persona.cluster_id)"
        :label="true"
        :alert="$_audiences_isAccessAlert(persona.cluster_id)"
        :warning="$_audiences_isAccessWarning(persona.cluster_id)"
      />
    </div>
  </div>
</template>

<script>
import SegmentChart from "@/components/SegmentChart";
import BaseImage from "@/components/base/BaseImage";
import { audiences } from "@/mixins/audiences";
import Pill from "@/components/Pill";

export default {
  name: "PersonaListItem",
  props: {
    persona: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
    detailed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [audiences],
  components: {
    SegmentChart,
    BaseImage,
    Pill,
  },
  computed: {
    description() {
      return this.$_audiences_getDescription(this.persona);
    },
    payerMix() {
      if (this.detailed) {
        return this.persona.payer_mix.filter((item) => {
          let matches = [
            "Commercial",
            "Medicare",
            "Public",
            "Other",
            "Uninsured",
          ];
          return matches.indexOf(item.label) !== -1;
        });
      }

      return this.persona.payer_mix.filter((item) => {
        let matches = ["Commercial", "Medicare", "All Other"];
        return matches.indexOf(item.label) !== -1;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";

.persona-list-item {
  display: flex;
  align-items: center;

  &__details {
    width: 232px;
    padding-left: 0.75rem;
  }

  &__metrics {
    width: calc(100% - 232px - 48px);
    max-width: 672px;
  }

  &__heading {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  &__subheading {
    font-size: 13px;
    color: $medium-gray;
  }

  &__image {
    width: 48px;
    height: 48px;
    background: $fade-gray;
    border-radius: 50%;
    overflow: hidden;

    .image-wrapper {
      overflow: hidden;
      width: 48px;
      height: 48px;

      &__item {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__actions {
    margin-left: auto;
  }

  &__label {
    margin-left: auto;
  }
}
</style>