import numeral from 'numeral';
import personaDescriptions from '@/data/personaDescriptions.json';

export const audiences = {
    data() {
        return {
            $_audiences_caseOpportunityFilter: "min",
        }
    },
    computed: {
        $_audiences_audience() {
            return this.$store.getters['audiences/audience'];
        },
        $_audiences_personas() {
            let list = [];
            if (this?.$_audiences_audience?.demographics) {
                this.$_audiences_audience.demographics.map((county) => {
                    county.zip_codes.map((zip_code) => {
                        let persona = {
                            cluster_id: zip_code.cluster_id,
                            niche_id: zip_code.niche_id,
                            incidence_min: zip_code.incidence_min,
                            incidence_max: zip_code.incidence_max,
                            prevalence_min: zip_code.prevalence_min,
                            prevalence_max: zip_code.prevalence_max,
                            total_population: zip_code.total_weighted_population,
                            total_households: zip_code.total_households,
                            total_private_insurance: zip_code.total_private_insurance,
                            total_medicare: zip_code.total_medicare,
                            total_public_insurance: zip_code.total_public_insurance,
                            total_uninsured: zip_code.total_uninsured,
                            total_other_insurance: zip_code.total_other_insurance,
                        };

                        let match = list.find((item) => {
                            return (
                                item.cluster_id == persona.cluster_id &&
                                item.niche_id == persona.niche_id
                            );
                        });

                        if (typeof match != "undefined") {
                            // Append metrics
                            match.incidence_min += zip_code.incidence_min;
                            match.incidence_max += zip_code.incidence_max;
                            match.prevalence_min += zip_code.prevalence_min;
                            match.prevalence_max += zip_code.prevalence_max;
                            match.total_population += zip_code.total_weighted_population;
                            match.total_households += zip_code.total_households;
                            match.total_private_insurance += zip_code.total_private_insurance;
                            match.total_medicare += zip_code.total_medicare;
                            match.total_public_insurance += zip_code.total_public_insurance;
                            match.total_uninsured += zip_code.total_uninsured;
                            match.total_other_insurance += zip_code.total_other_insurance;
                        } else {
                            // Add default metrics
                            list.push(persona);
                        }
                    });
                });
            }

            // Get payer mix for each persona
            list = list.map((item) => {
                item.payer_mix = this.$_audiences_payerMix(item);
                item.case_opportunities = this.$_audiences_getCaseOpportunities(item);
                return item;
            });

            return list;
        }
    },
    methods: {
        $_audiences_payerMix(persona) {
            let list = [
                {
                    total:
                        Math.round((persona.total_private_insurance / persona.total_population) *
                            (persona[`incidence_${this.$data.$_audiences_caseOpportunityFilter}`] +
                                persona[`prevalence_${this.$data.$_audiences_caseOpportunityFilter}`])),
                    label: "Commercial",
                },
                {
                    total:
                        Math.round((persona.total_medicare / persona.total_population) *
                            (persona[`incidence_${this.$data.$_audiences_caseOpportunityFilter}`] +
                                persona[`prevalence_${this.$data.$_audiences_caseOpportunityFilter}`])),
                    label: "Medicare",
                },
                {
                    total:
                        Math.round(((persona.total_other_insurance +
                            persona.total_public_insurance +
                            persona.total_uninsured) /
                            persona.total_population) *
                            (persona[`incidence_${this.$data.$_audiences_caseOpportunityFilter}`] +
                                persona[`prevalence_${this.$data.$_audiences_caseOpportunityFilter}`])),
                    label: "All Other",
                },
                {
                    total:
                        Math.round((
                            persona.total_public_insurance /
                            persona.total_population) *
                            (persona[`incidence_${this.$data.$_audiences_caseOpportunityFilter}`] +
                                persona[`prevalence_${this.$data.$_audiences_caseOpportunityFilter}`])),
                    label: "Public",
                },
                {
                    total:
                        Math.round((
                            persona.total_other_insurance /
                            persona.total_population) *
                            (persona[`incidence_${this.$data.$_audiences_caseOpportunityFilter}`] +
                                persona[`prevalence_${this.$data.$_audiences_caseOpportunityFilter}`])),
                    label: "Other",
                },
                {
                    total:
                        Math.round((
                            persona.total_uninsured /
                            persona.total_population) *
                            (persona[`incidence_${this.$data.$_audiences_caseOpportunityFilter}`] +
                                persona[`prevalence_${this.$data.$_audiences_caseOpportunityFilter}`])),
                    label: "Uninsured",
                }
            ];

            return list;
        },
        $_audiences_getCaseOpportunities(persona) {
            return numeral(
                persona[`incidence_${this.$data.$_audiences_caseOpportunityFilter}`] +
                persona[`prevalence_${this.$data.$_audiences_caseOpportunityFilter}`]
            ).format("0,0");
        },
        $_audiences_getDescription(persona) {
            return personaDescriptions.find((item) => {
                return item.cluster_id == persona.cluster_id && item.niche_id == persona.niche_id;
            })
        },
        $_audiences_getAccessLabelByClusterId(id) {
            if (id == 4) {
                return "High Access";
            } else if (id == 1 || id == 3) {
                return "Low Access";
            } else if (id == 2) {
                return "Moderate Access";
            }

            return null;
        },
        $_audiences_isAccessAlert(id) {
            if (id == 1 || id == 3) {
                return true;
            }

            return false;
        },
        $_audiences_isAccessWarning(id) {
            if (id == 2) {
                return true;
            }

            return false;
        },
        $_audiences_getConditionInfoByZipCode(zipCodes) {
            let list = [];
            zipCodes.map((item) => {
                const totalCasesMin = item.incidence_min + item.prevalence_min;
                const totalCasesMax = item.incidence_max + item.prevalence_max;
                list.push({
                    zip_code: item.zip_code,
                    total_cases_estimate_minimum: totalCasesMin,
                    total_cases_estimate_maximum: totalCasesMax,
                    new_case_estimate_minimum: item.incidence_min,
                    new_case_estimate_maximum: item.incidence_max,
                    existing_case_estimate_minimum: item.prevalence_min,
                    existing_case_estimate_maximum: item.prevalence_max,
                    estimated_total_cases_by_private_insurance_minimum: (item.total_weighted_population) ? Math.round((item.total_private_insurance / item.total_weighted_population) * totalCasesMin) : 0,
                    estimated_total_cases_by_private_insurance_maximum: (item.total_weighted_population) ? Math.round((item.total_private_insurance / item.total_weighted_population) * totalCasesMax) : 0,
                    estimated_total_cases_by_public_insurance_minimum: (item.total_weighted_population) ? Math.round((item.total_public_insurance / item.total_weighted_population) * totalCasesMin) : 0,
                    estimated_total_cases_by_public_insurance_maximum: (item.total_weighted_population) ? Math.round((item.total_public_insurance / item.total_weighted_population) * totalCasesMax) : 0,
                    estimated_total_cases_by_medicare_minimum: (item.total_weighted_population) ? Math.round((item.total_medicare / item.total_weighted_population) * totalCasesMin) : 0,
                    estimated_total_cases_by_medicare_maximum: (item.total_weighted_population) ? Math.round((item.total_medicare / item.total_weighted_population) * totalCasesMax) : 0,
                    estimated_total_cases_by_other_insurance_minimum: (item.total_weighted_population) ? Math.round((item.total_other_insurance / item.total_weighted_population) * totalCasesMin) : 0,
                    estimated_total_cases_by_other_insurance_maximum: (item.total_weighted_population) ? Math.round((item.total_other_insurance / item.total_weighted_population) * totalCasesMax) : 0,
                    estimated_total_cases_by_uninsured_minimum: (item.total_weighted_population) ? Math.round((item.total_uninsured / item.total_weighted_population) * totalCasesMin) : 0,
                    estimated_total_cases_by_uninsured_maximum: (item.total_weighted_population) ? Math.round((item.total_uninsured / item.total_weighted_population) * totalCasesMax) : 0,
                    healthcare_access: item.access
                });
            });

            return list;
        }
    }
}